import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Button, ButtonGroup, Checkbox, FormControlLabel, Radio, RadioGroup, Slider, TextField } from '@mui/material';
import StepsList from './StepsList';
import EditStepDialog from './EditStepDialog';
import YouTubePlayer from '../YouTubePlayer';
import { domain } from '../../App';
import AudioPreview from '../Media/AudioPreview';

const buttonStyles = {
    width: '100%', 
    padding: '0.6rem',
    borderRadius: '4px 4px 0 0'
};
const containerStyles = {
    display: 'grid', 
    gridTemplateColumns: '300px 1fr', 
    gap: '1rem', 
    marginTop: '1rem',
    height: 'calc(100% - 98px)',

}
const infoTextStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px'
}
const ALL_STEPS = gql`
    query AllSteps($activityId: UUID!) {
        allOcgTSteps(condition: {activityId: $activityId}) {
            nodes {
                id
                imageUrl
                instructions
                stepType
                timer
                timerUnit
                timerDisabled
                sudsScale
                title
                audioType
                audioUrl
                videoType
                videoUrl
                autoAdvance
                journal
                multipleAnswers
                answers
            }
        }
    }
`;
const GET_ACTIVITY = gql`
    query GetActivity($id: UUID!) {
        ocgTActivityById(id: $id) {
            steps
        }
    }
`;


export default function StepsTable({ activity }) {
    const navigate = useNavigate();
    const [SUD, setSUD] = React.useState(0);
    const [openStepDialog, setOpenStepDialog] = React.useState(false);
    const [steps, setSteps] = React.useState([]);
    const allSteps = useQuery(ALL_STEPS, {
        variables: { activityId: activity.id },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const getActivity = useQuery(GET_ACTIVITY, {
        variables: { id: activity.id },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const [editDialogStep, setEditDialogStep] =  React.useState(null);
    const [selected, setSelected] = React.useState(steps.length > 0 ? steps[0].id : null);
    const step = steps.find(step => step.id === selected);


    // Find the index of the selected id in the steps array
    const currentIndex = steps.findIndex(step => step.id === selected);
    // Disable the prev button if the selected id is at the start of the list
    const isAtStart = currentIndex === 0;
    // Disable the next button if the selected id is at the end of the list
    const isAtEnd = currentIndex === steps.length - 1;

    function getIndexFromSelected(selected) {
        return steps.findIndex(step => step.id === selected);
    }
    function changeSteps(step, data, isNew) {
        setSteps(prevSteps => {
            if (isNew) {
                return [...prevSteps, 
                    {
                        ...step,
                        id: data.id,
                        audioUrl: step.audioType !== 'URL' ? data.audio_url : step.audioUrl,
                        videoUrl: step.videoType !== 'YouTube' ? data.video_url : step.videoUrl,
                        imageUrl: data.image_url
                    }
                ];
            } else {
                return prevSteps.map(prevStep => {
                    if (prevStep.id === step.id) {
                        if (step.audioType !== 'URL') step.audioUrl = data.audio_url;
                        if (step.videoType !== 'YouTube') step.videoUrl = data.video_url;
                        step.imageUrl = data.image_url;
                        return step;
                    }
                    return prevStep;
                });
            }
        });
    }
    function deleteStep() {
        setSteps(steps => steps.filter(step => step.id !== selected));
        setSelected(null);
    }
    function addStep() {
        setEditDialogStep(null);
        setOpenStepDialog(true);
    }
    function editStep() {
        setEditDialogStep(step);
        setOpenStepDialog(true);
    }
    async function copyStep() {
        await fetch(`${domain}/api/steps/copy`, {
            method: 'POST', 
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({ id: selected })
        }).then(async res => {
            if (res.status === 200) {
                const step = await res.json();
                allSteps.refetch();
                getActivity.refetch();
                setSelected(step.id);
            }
            else if (res.status === 401) {
                navigate('/login', { state: { returnToPage: true }});
            }
        });
    }
    function prev() {
        const index = getIndexFromSelected(selected);
        const newIndex = (index > 0) ? index - 1 : index;
        const newSelected = steps[newIndex].id;
        setSelected(newSelected);
    }
    function next() {
        const index = getIndexFromSelected(selected);
        const newIndex = (index < steps.length - 1) ? index + 1 : index;
        const newSelected = steps[newIndex].id;
        setSelected(newSelected);
    }
    React.useEffect(() => {
        const allStepsError = allSteps?.error;
        if (allStepsError?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
            return;
        }
        let newSteps = allSteps?.data?.allOcgTSteps?.nodes;
        let steps = getActivity?.data?.ocgTActivityById?.steps;
        if (newSteps && steps) {
            const newStepsSorted = [...newSteps].sort(function (a, b) {
                return steps.indexOf(a.id) - steps.indexOf(b.id);
            });
            setSteps(newStepsSorted);
        }
    }, [getActivity, allSteps, setSteps, navigate]);
    function renderOtherFields() {
        if (step?.stepType === 1) {
            if (step?.videoType === 'YouTube') {
                return step?.videoUrl ? <YouTubePlayer url={step?.videoUrl} width="100%" height="281px" /> : null;
            }
            else {
                return (
                    <video key={step.id} playsInline style={{width: '100%'}} controls>
                        <source src={`${domain}/files/steps/${step?.id}/${step?.videoUrl}`} />
                    </video>
                );
            }
        }
        else if (step?.stepType === 2) {
            return <img src={`${domain}/files/steps/${step?.id}/${step?.imageUrl}`} style={{height: '340px'}} alt={step?.title} />;
        }
        else if (step?.stepType === 3) {
            return <TextField label="Type in your response here..." multiline rows={12} style={{width:'100%'}} />;
        }
        else if (step?.stepType === 4) {
            return <Button aria-label="Record a Video" variant="contained" disableElevation>Record a Video</Button>;
        }
        else if (step?.stepType === 5) {
            return <Button aria-label="Take a Picture" variant="contained" disableElevation>Take a Picture</Button>;
        }
        else if (step?.stepType === 6) {
            const sudsScaleArr = step?.sudsScale?.split('-');
            return <Slider aria-label="SUDs Rating" value={SUD} onChange={(e, newValue) => setSUD(newValue)} 
                valueLabelDisplay="auto" step={sudsScaleArr[0] ?? 1} max={sudsScaleArr[1] ?? 10} sx={{
                    marginTop: '2rem',
                    width: '90%',
                    "& .MuiSlider-valueLabelLabel": {
                      fontSize: "20px",
                      fontWeight: "bold"
                    }
                  }}/>;
        }
        else if (step?.stepType === 7) {
            return (
                <RadioGroup style={{display: 'flex', alignContent: 'flex-start', marginLeft: '1rem'}}>
                    {step?.answers?.map((answer, index) => (
                        <FormControlLabel key={index} value={answer} label={answer} style={{ textAlign: 'left' }}
                            control={step?.multipleAnswers ? <Checkbox /> : <Radio />} />
                    ))}
                </RadioGroup>
            );
        }
        else if (step?.stepType === 9) {
            return (<AudioPreview step={step} />);
         }
    }
    return (
        <div style={containerStyles}>
            <div style={{height:'calc(100vh - 215px)', backgroundColor: '#e0e0e0', borderRadius:'5px'}}>
                {/* <AddStepButton activityId={activity.id} addStep={addStep} /> */}
                <Button onClick={addStep} variant="contained" size="large" disableElevation style={buttonStyles}>Add Step</Button>
                <EditStepDialog openDialog={openStepDialog} setOpenDialog={setOpenStepDialog} activityId={activity?.id}
                    step={editDialogStep} setSteps={changeSteps} setSelected={setSelected} deleteStep={deleteStep} />
                <div style={{overflow: 'scroll', height: 'calc(100% - 50px'}}>
                    <StepsList activityId={activity.id} steps={steps} selected={selected} setSteps={setSteps} setSelected={setSelected} />
                </div>
            </div>
            <div style={{height:'calc(100vh - 215px)', maxWidth:'700px', backgroundColor: '#e0e0e0', borderRadius:'5px', overflow: 'hidden'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', margin: '0.5rem', alignItems: 'center'}}>
                    <ButtonGroup aria-label="Previous and Next" size="small" variant="contained" disableElevation>
                        <Button onClick={prev} disabled={selected === null || isAtStart}>Previous</Button>
                        <Button onClick={next} disabled={selected === null || isAtEnd}>Next</Button>
                    </ButtonGroup>
                    <small>Step {selected === null ? 0 : getIndexFromSelected(selected) + 1} of {steps.length}</small>
                    <ButtonGroup aria-label="Delete" size="small" variant="contained" disableElevation>
                        <Button onClick={editStep} disabled={selected === null}>Edit Step</Button>
                        <Button onClick={copyStep} disabled={selected === null}>Copy Step</Button>
                    </ButtonGroup>
                </div>
                { steps.length === 0 ?
                <div style={infoTextStyles}>
                    <p>Build your activity with chronological steps.</p>
                    <p>Click <b>Add Step</b> to get started.</p>
                </div> : null }
                { selected !== null ?
                <div style={{paddingBottom:'1.5rem', margin:'auto', maxWidth:'500px', textAlign:'center', overflow: 'scroll', height: 'calc(100% - 1rem - 30.75px)'}}>
                    <h2>{step?.title}</h2>
                    <p style={{marginBottom: '2rem'}}>{step?.instructions}</p>
                    {renderOtherFields()}
                </div> : null }
            </div>
        </div>
    )
}