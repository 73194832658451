import * as React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';

const DELETE_ASSIGNMENT = gql`
    mutation DeleteAssignment($assignmentId: Int!) {
        deleteOcgTAssignmentById(input: {id: $assignmentId}) {
            deletedOcgTAssignmentId
        }
    }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeleteAssignmentDialog({ assignmentToDelete, setAssignmentToDelete, assignments, assignmentsDeleted }) {
    const [deleteAssignment, { data, loading, error }] = useMutation(DELETE_ASSIGNMENT, {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    function unassign() {
        deleteAssignment({
            variables: { assignmentId: assignmentToDelete?.id }
        }).then((result) => {
            assignmentsDeleted();
            handleClose();
        }).catch((error) => {
            console.error(error);
        });
    }
    function unassignAll() {
        const assignmentsToDelete = assignments.filter((assignment) => assignment.activityId === assignmentToDelete?.activityId);
        Promise.allSettled(
            assignmentsToDelete.map((assignment) => {
                return deleteAssignment({
                    variables: { assignmentId: assignment.id }
                });
            })
        ).then((results) => {
            assignmentsDeleted();
            handleClose();
        }).catch((error) => {
            console.error(error);
        });
    }
    function morethanOneAssignment() {
        return assignments?.filter((assignment) => assignment.activityId === assignmentToDelete?.activityId).length > 1 || false;
    }
    function handleClose() {
        setAssignmentToDelete(null);
    };
    React.useEffect(() => {
        if (error?.networkError?.statusCode === 401) {
            setAssignmentToDelete(null);
        }
    }, [error, setAssignmentToDelete]);
    return (
        <Dialog
            open={assignmentToDelete !== null}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="delete assignment"
            PaperProps={{ sx: { width: "350px", maxWidth:'350px', height: "250px", overflow: 'hidden' } }}
        >
            <DialogTitle style={{textAlign: 'center', backgroundColor:'#f9f9f9'}}>Unassign Assignment</DialogTitle>
            <DialogContent>
                { morethanOneAssignment() ?
                    <p>Are you sure you want to unassign this activity? You can also choose to unassign all future assignments for this activity.</p>
                    :
                    <p>Are you sure you want to unassign this activity?</p>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="small" style={{height:'100%'}} disableElevation onClick={handleClose}>Cancel</Button>
                <Button variant="contained" size="small" disableElevation onClick={unassign}>Unassign</Button>
                { morethanOneAssignment() ?
                    <Button variant="contained" size="small" disableElevation onClick={unassignAll}>Unassign All</Button>
                : null }
            </DialogActions>
            
        </Dialog>
    );
}