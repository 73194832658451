import * as React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Button, Breadcrumbs, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    TableSortLabel, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material/';
import Link from '../Link';
import { breadcrumbStyles } from '../../scripts/styles';
import db from '../../scripts/db';
import AssignActivityDialog from '../Activities/AssignActivityDialog';
import DeleteAssignmentDialog from '../Assignments/DeleteAssignmentsDialog';
import { domain } from '../../App';

const dialogStyles = {
    margin: '1rem 1rem 1rem calc(240px + 1rem)',
    maxWidth: '1060px',
    width: 'calc(100% - 2rem - 240px)',
    aspectRatio: '1280/720',
    border: 'none',
    borderRadius: '5px',
    boxShadow: '#00000029 2px 2px 5px 0px',
    padding: 0,
    zIndex: 20
};
const GET_PATIENT = gql`
    query GetPatient($id: Int!) {
        ocgTPatientById(id: $id) {
            name
            email
            id
            clinicianId
            createdAt
        }
    }
`;
const GET_ASSIGNMENTS = gql`
    query GetAssignments($patientId: Int!, $orderBy: [CondensedAssignmentsOrderBy!]) {
        allCondensedAssignments(condition: {patientId: $patientId}, orderBy: $orderBy) {
            nodes {
                id
                activityId
                name
                dueDate
                endDate
                status
                patientResponses
                patientResponsesCount
            }
            totalCount
        }
    }
`;
const GET_ASSIGNMENTS_DATE_FILTER = gql`
    query GetAssignments($patientId: Int!, $orderBy: [CondensedAssignmentsOrderBy!], $lessThanOrEqualTo: Datetime!, $greaterThanOrEqualTo: Datetime!) {
        allCondensedAssignments(
            condition: {patientId: $patientId}, 
            orderBy: $orderBy, 
            filter: {dueDate: {lessThanOrEqualTo: $lessThanOrEqualTo, greaterThanOrEqualTo: $greaterThanOrEqualTo}}
        ) {
            nodes {
                id
                activityId
                name
                dueDate
                endDate
                status
                patientResponses
                patientResponsesCount
            }
            totalCount
        }
    }
`;

function getHighestNumberOfSteps(assignments) {
    let highestNumberOfSteps = 0;

    for (let i = 0; i < assignments.length; i++) {
        const totalCount = assignments[i]?.patientResponsesCount;
        if (totalCount > highestNumberOfSteps) {
            highestNumberOfSteps = totalCount;
        }
    }

    return highestNumberOfSteps;
} 
function safeParsePatientJson(data) {
    try {
        const parsed = JSON.parse(data);
        return parsed?.data || '';
    } catch (error) {
        console.info('Failed to parse JSON:', error);
        console.info('Data:', data);
        return '';
    }
}
function renderTimelineDate(date) {
    if (date) {
        return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(date));
    }
    else return '';
}

export default function PatientDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const patientId = parseInt(params?.id);
    const dialogRef = React.useRef(null);
    const [dialogResponse, setDialogResponse] = React.useState(null);

    const [openAssignDialog, setOpenAssignDialog] = React.useState(false);

    const [selectedRow, setSelectedRow] = React.useState(null);
    const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
    const clinicianId = clinician?.id;

    const [assignmentToDelete, setAssignmentToDelete] = React.useState(null);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('due_date');

    const [dateFilter, setDateFilter] = React.useState(7);
    const [lessThanOrEqualTo, setLessThanOrEqualTo] = React.useState(null);
    const [greaterThanOrEqualTo, setGreaterThanOrEqualTo] = React.useState(null);
    const getPatient = useQuery(GET_PATIENT, {
        variables: { id: patientId },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const getAssignments = useQuery(dateFilter === null ? GET_ASSIGNMENTS : GET_ASSIGNMENTS_DATE_FILTER, {
        variables: { 
            clinicianId, patientId,
            orderBy: [`${orderBy.toUpperCase()}_${order.toUpperCase()}`, 'DUE_DATE_ASC'],
            lessThanOrEqualTo, greaterThanOrEqualTo 
        },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const getPatientError = getPatient?.error;
    const getAssignmentsError = getAssignments?.error;
    const patient = getPatient?.data?.ocgTPatientById;
    const assignments = getAssignments?.data?.allCondensedAssignments?.nodes;
    if (patient) db.appbar.put({ key: 1, title: patient.name });
  
    function handleSort(property) {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }
    function showDialog(response) {
        setDialogResponse(response);
        dialogRef.current.showModal();
    }
    async function close() {
        dialogRef.current.close();
        setDialogResponse(null);
    }
    function renderData(response) {
        if (response?.stepType === 4) {
            return <TableCell key={response?.id} align="left" component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
                    <Button variant="text" size="small" onClick={() => showDialog(response)} disableElevation>Play Video</Button>
                </TableCell>;
        }
        if (response?.stepType === 5) {
            return <TableCell key={response?.id} align="left" component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
                    <Button variant="text" size="small" onClick={() => showDialog(response)} disableElevation>Show Image</Button>
                </TableCell>;
        }
        else {
            const data = safeParsePatientJson(response?.data);
            return <TableCell key={response?.id} style={{ maxWidth: '150px' }} 
            align="left" component="th" scope="row">
                <Tooltip title={data} style={{ display: 'inline-block', width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} arrow>
                    <span>{data}</span>
                </Tooltip>
            </TableCell>;
        }
    }
    function renderStepCells(assignments) {
        const highestNumberOfSteps = getHighestNumberOfSteps(assignments || []);

        // Create an array of JSX elements
        const stepCells = [];
        for (let i = 1; i <= highestNumberOfSteps; i++) {
            stepCells.push(
                <TableCell key={i} align="left" style={{whiteSpace: 'nowrap'}}>
                    <b>Step {i}</b>
                </TableCell>
            );
        }
    
        return stepCells;
    }
    function renderPatientResponses(assignment) {
        const patientResponses = JSON.parse(assignment?.patientResponses || '[]');
        return <React.Fragment>
            {patientResponses.map((response) => (
                renderData(response)
            ))}
        </React.Fragment>

    }
    // Create a color array based on the due dates
    const colorArray = React.useMemo(() => {
        if (!assignments) return [];

        let currentColor = 'white';
        let previousName = null;
        let previousDueDate = null;
        let previousStatus = null;
        return assignments.map((assignment) => {
            if (orderBy === 'name') {
                if (assignment.name === previousName) {
                    return currentColor;
                } else {
                    currentColor = currentColor === 'white' ? '#f0f0f0' : 'white';
                    previousName = assignment.name;
                    return currentColor;
                }
            } else if (orderBy === 'due_date') {
                // Extract only the date part (YYYY-MM-DD)
                const currentDate = assignment.dueDate.split('T')[0];

                if (currentDate === previousDueDate) {
                    return currentColor;
                } else {
                    currentColor = currentColor === 'white' ? '#f0f0f0' : 'white';
                    previousDueDate = currentDate;
                    return currentColor;
                }
            } else if (orderBy === 'status') {
                if (assignment.status === previousStatus) {
                    return currentColor;
                } else {
                    currentColor = currentColor === 'white' ? '#f0f0f0' : 'white';
                    previousStatus = assignment.status;
                    return currentColor;
                }
            } else {
                return 'white';
            }
        });
    }, [assignments, orderBy]);
    React.useEffect(() => {
        if (dateFilter !== null) {
            // For past dates
            if (dateFilter >= 0) {
                const today = new Date();
                today.setHours(23, 59, 59, 999);
                const newLessThanOrEqualTo = today.toISOString();
                today.setDate(today.getDate() - dateFilter);
                today.setHours(0, 0, 0, 0);
                const newGreaterThanOrEqualTo = today.toISOString();
                setLessThanOrEqualTo(newLessThanOrEqualTo);
                setGreaterThanOrEqualTo(newGreaterThanOrEqualTo);
            } 
            // For future dates
            else {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const newGreaterThanOrEqualTo = today.toISOString();
                today.setDate(today.getDate() - dateFilter);
                today.setHours(23, 59, 59, 999);
                const newLessThanOrEqualTo = today.toISOString();
                setGreaterThanOrEqualTo(newGreaterThanOrEqualTo);
                setLessThanOrEqualTo(newLessThanOrEqualTo);
            }
        }
    }, [dateFilter]);

    React.useEffect(() => {
        if (getPatientError?.networkError?.statusCode === 401
            || getAssignmentsError?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [getPatientError, getAssignmentsError, navigate]);
    return (
        <React.Fragment>
            <Breadcrumbs sx={breadcrumbStyles} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/patients">Patients</Link>
                <Typography color="text.primary">{patient?.name}</Typography>
            </Breadcrumbs>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography style={{margin:'2rem 0', fontSize:'2rem'}} variant="h4" noWrap component="div">Assignments Quick View</Typography>
                <Button variant="contained" size="small" style={{marginLeft:'1rem'}} onClick={() => setOpenAssignDialog(true)} disableElevation>Assign Activity</Button>
                <Button variant="contained" size="small" style={{marginLeft:'1rem'}} disableElevation disabled>Edit Patient Profile</Button>
            </div>
            <AssignActivityDialog clinicianId={clinicianId} patientId={patientId} openDialog={openAssignDialog} 
                setOpenDialog={setOpenAssignDialog} assignmentsCreated={() => getAssignments.refetch()} />
            <DeleteAssignmentDialog assignmentToDelete={assignmentToDelete} setAssignmentToDelete={setAssignmentToDelete} 
                assignments={assignments} assignmentsDeleted={() => getAssignments.refetch()} />
            <ToggleButtonGroup color="primary" value={dateFilter} exclusive size='small'
                onChange={(e, newDateFilter) => setDateFilter(newDateFilter)} aria-label="days filter">
                <ToggleButton value={0}>Show today</ToggleButton>
                <ToggleButton value={7}>Show last 7 days</ToggleButton>
                <ToggleButton value={14}>Show last 14 days</ToggleButton>
                <ToggleButton value={30}>Show last 30 days</ToggleButton>
                <ToggleButton value={null}>Show all</ToggleButton>
                <ToggleButton value={-731}>Show future</ToggleButton>
            </ToggleButtonGroup>
            <Paper style={{ padding: '16px', marginTop: '1rem' }}>
                <Typography variant="body2">
                    Click on column headings to sort. Slide bar to the right to see all client-side responses. To view both the provider-side prompts and client-side responses, click the blue assignment titles for an in-depth view.
                </Typography>
            </Paper>
            <TableContainer style={{flex: 1, marginTop: '1rem'}} component={Paper}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="patients table" size="small">
                    <TableHead style={{backgroundColor:'#e0e0e0'}}>
                        <TableRow>
                            <TableCell align="left" style={{whiteSpace: 'nowrap'}}>
                                <TableSortLabel active={orderBy === 'name'} direction={orderBy === 'name' ? order : 'asc'}
                                    onClick={() => handleSort('name')}><b>Activity Title</b></TableSortLabel>
                            </TableCell>
                            <TableCell align="left" style={{whiteSpace: 'nowrap'}}>
                                <TableSortLabel active={orderBy === 'due_date'} direction={orderBy === 'due_date' ? order : 'asc'}
                                    onClick={() => handleSort('due_date')}><b>Due Date</b></TableSortLabel>
                            </TableCell>
                            <TableCell align="left" style={{whiteSpace: 'nowrap'}}>
                                <TableSortLabel active={orderBy === 'status'} direction={orderBy === 'status' ? order : 'asc'}
                                    onClick={() => handleSort('status')}><b>Status</b></TableSortLabel>
                            </TableCell>
                            { dateFilter === -731 ? <TableCell align="left" style={{whiteSpace: 'nowrap'}} /> : null }
                            {renderStepCells(assignments)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assignments?.map((assignment, index) => {
                            const isSelected = assignment.id === selectedRow;
                            const rowColor = isSelected ? '#c8e6c9' : colorArray[index];
                            return (
                                <TableRow key={assignment.id} sx={{ height: '42px', backgroundColor: rowColor, '&:last-child td, &:last-child th': { border: 0 } }}
                                    onClick={() => setSelectedRow(assignment.id)}>
                                    <TableCell align="left" component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
                                        <Link underline="hover" color="inherit" href={`/patients/${patientId}/assignments/${assignment.id}`} sx={{
                                            color: '#2096f3',
                                            fontWeight: 'bold',
                                            textDecoration: 'underline',
                                            transition: 'color 0.3s ease, text-decoration 0.3s ease',
                                            '&:hover': {
                                                color: 'darkblue',
                                                textDecoration: 'none'
                                            }
                                        }}>
                                            {assignment?.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left" component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
                                        {renderTimelineDate(assignment?.dueDate)}
                                    </TableCell>
                                    <TableCell align="left"><Chip label={assignment?.status?.toUpperCase()} size="small" style={{borderRadius: '4px'}} /></TableCell>
                                    { dateFilter === -731 ? <TableCell align="left" style={{whiteSpace: 'nowrap'}} key={assignment.id}>
                                        <Button variant="text" size="small" color="error" onClick={() => setAssignmentToDelete(assignment)} disableElevation>Unassign</Button>
                                    </TableCell> : null }
                                    {renderPatientResponses(assignment)}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <dialog ref={dialogRef} style={dialogStyles} onClick={close}>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', height: '100%'}}>
                    { dialogResponse?.stepType === 4 ? 
                    <video key={dialogResponse?.id} playsInline style={{width: '100%'}} controls>
                        <source src={`${domain}/files/patient-responses/${dialogResponse?.id}/${dialogResponse?.videoUrl}`} />
                    </video> : null }

                    { dialogResponse?.stepType === 5 ? 
                    <img src={`${domain}/files/patient-responses/${dialogResponse?.id}/${dialogResponse?.imageUrl}`} style={{height: '100%'}} alt={dialogResponse?.title} />
                    : null }
                </div>
            </dialog>
        </React.Fragment>
    )
}